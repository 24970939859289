<template>
  <div class=container>
      <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2">How Much is the Performance Gain?</font></div></td>
          </tr>
          <tr>
            <td>In order to compute the performance gain of diffuse structured light quantitatively, we consider spheres as they have all surface normals. We consider a glossy sphere for specularities and a Lambertian sphere for shadows. <strong>By clicking on each of the thumbnail images shown below, you can play videos and see the corresponding high resolution image.</strong><br>
(<router-link :to="{path:'/projects/categories/project',query:{cid:this.$route.query.cid,pid:this.$route.query.pid}}">Back to Main Project Page</router-link>)</td>
          </tr>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody><tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td><strong>Specularities</strong></td>
                    <td><div align="right"><strong><br>
                    </strong></div></td>
                  </tr>
                </tbody></table>
                  <div align="right"></div></td>
                </tr>
              
              <tr>

                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/quant/CoverageHemisphere-TS-HighSpecular.mov"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/quant/Frame001-001.png" width="200" height="212" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Very smooth material</strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/quant/CoverageHemisphere-TS-ModerateSpecular.mov"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/quant/Frame002-001.png" width="200" height="212" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Moderately rough</strong></div></td>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/quant/CoverageHemisphere-TS-LowSpecular.mov"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/quant/Frame003-001.png" width="200" height="212" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Very rough</strong></div></td>
                    </tr>
                </tbody></table>


                <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td> Here, we illustrate the coverage of a glossy sphere. Coverage is the fraction of the sphere which can be captured reliably. For illustration, the sphere is mapped to a circle. The red portion of the sphere is too dark (intensity less than 10 grey levels) to be captured faithfully. This part is considered uncovered. The covered portion is shown in grey. For very smooth material, a very small portion of the sphere is covered without the diffuser (scattering angle of 1 degree corresponds to no diffuser). As the diffuser scattering angle increases, the coverage increases. <strong> Click on the images to play videos. </strong> Similar trend can be observed for more rough materials. </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table></td>
                </tr>
              <tr>

              <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/quant/CoverageSpecularSphere-01.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/quant/CoverageSpecularSphere-01.png" width="200" height="153" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Scene</strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/quant/CoverageSpecularSphere-02.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/quant/CoverageSpecularSphere-02.png" width="200" height="153" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Direct</strong></div></td>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/quant/CoverageSpecularSphere-03.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/quant/CoverageSpecularSphere-03.png" width="200" height="153" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Scene</strong></div></td>
                    </tr>
                </tbody></table>
                  <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td> <div align="center"> Plots of the coverage fraction for different material properties. </div></td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table></td>
                </tr>
          </tbody></table></td>
          </tr>






          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody><tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td><strong>Shadows</strong></td>
                    <td><div align="right"><strong><br>
                    </strong></div></td>
                  </tr>
                </tbody></table>
                  <div align="right"></div></td>
                </tr>
              

              <tr>

              <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/quant/SphereCoverageShadow.mov"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/quant/Frame001.png" width="230" height="240" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Scene</strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/quant/CoveragePlot.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/quant/CoveragePlot.png" width="230" height="182" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Direct</strong></div></td>
                    </tr>
                </tbody></table>
                
                  <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td> To quantify the performance gain in the presence of shadows, we consider the coverage of a Lambertian sphere. With conventional SL, only the top half of the sphere receives light. With the diffuser, portion of the bottom hemisphere is covered as well. As in the case of specularities, coverage increases with increasing scattering angle. <strong> Click on the image on left to play video. </strong> </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table></td>
                </tr>
          </tbody></table></td>
          </tr>








          <tr>
            <td>&nbsp;</td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
}
.table{
        margin:auto;
}
</style>